import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Estevan Jantsk"

    return (
      <Wrapper>
        <Layout location={this.props.location} title={siteTitle}>
          <SEO
            title="Home"
            keywords={[`blog`, `gatsby`, `javascript`, `react`]}
          />
          <Description>
            BRAZILIAN SOFTWARE ENGINEER FOR REACT.JS, ANGULAR, NODE.JS AND GRAPHQL
          </Description>
        </Layout>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`

`

const Description = styled.h2`
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
`

export default IndexPage
