import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Navbar from '../components/navbar'
import { rhythm } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    let header

    if (location.pathname === rootPath || location.pathname === blogPath) {
      header = (
        <Title>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `#fff`,
            }}
            to={location.pathname === blogPath ? `/blog/` : `/`}
          >
            {title}
          </Link>
        </Title>
      )
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/blog/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <Wrapper>
        <div
          style={{
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            textAlign: 'center',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: 'url("./background.jpeg") no-repeat center',
            backgroundSize: 'cover',
            position: 'relative'
          }}
        >
          <Shadow />
          <Navbar />
          <header style={{ zIndex: 1 }}>{header}</header>
          <main style={{ zIndex: 1 }}>{children}</main>
        </div>
        <Footer>
          © {new Date().getFullYear()}, Estevan Jantsk
        </Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

const Title = styled.div`
  font-size: 48px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 5.5px;
  text-transform: uppercase;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
`

const Shadow = styled.div`
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: .5;
`

export default Layout
